import React from 'react';
import Link from 'gatsby-link';
import cx from 'classnames';
import styles from './form.module.scss';

const FollowForm = () => (
  <div className={styles.wrapper}>
    <div className={styles.highlight}>
      <p>Startup Mentor. Maker. Wanderer. </p>
      <p>Writing about Self-Improvement, Startups and Philosophy.</p>
    </div>
    {true &&
      <form
        action="https://ramonrecuero.us20.list-manage.com/subscribe/post?u=280e7e13eccf242c262ffbbbf&amp;id=ccec375126"
        method="post" id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form" className={cx("validate", styles['follow-form-embed'])} target="_blank" noValidate>
        <div id="mc_embed_signup_scroll">
      	  <input type="email" name="EMAIL" className="email" id="mce-EMAIL" placeholder="Your email address" required/>
          <div className={styles.hidden} aria-hidden="true">
            <input type="text" name="b_280e7e13eccf242c262ffbbbf_ccec375126" tabIndex="-1" value=""/>
          </div>
          <button type="submit" name="subscribe" id="mc-embedded-subscribe">
            Follow
          </button>
          <p className={styles.footern}> Follow for updates on what I am creating </p>
        </div>
      </form>
    }
    {false &&
      <form action="https://gumroad.com/follow_from_embed_form" className={styles["follow-form-embed"]} method="post">
        <input name="seller_id" type="hidden" value="3988107569993" />
        <input name="email" placeholder="Your email address" type="email" />
        <button data-custom-highlight-color="" type="submit">
          Follow
        </button>
        <p className={styles.footern}> Follow for updates on what I am creating </p>
      </form>
    }
  </div>
)

export default FollowForm;
