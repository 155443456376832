import React from 'react'
import Link from 'gatsby-link'
import GripCover from '../components/GripCover'
import TemplateWrapper from '../layouts/index'
import FollowForm from '../components/FollowForm'

export default class GripPage extends React.Component {

  componentDidMount() {
    setTimeout(() => {
      window.dojoRequire(["mojo/signup-forms/Loader"], function(L) { L.start({"baseUrl":"mc.us20.list-manage.com","uuid":"280e7e13eccf242c262ffbbbf","lid":"ccec375126","uniqueMethods":true}) })
      document.cookie = "MCPopupClosed=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC";
    }, 1000);
  }

  render() {
    return (
      <TemplateWrapper>
        <div style={{width: '100%'}}>
          <GripCover />
          <div style={{maxWidth: '800px', margin: '0 auto'}}>
            <FollowForm />
          </div>
        </div>
      </TemplateWrapper>
    )
  }
}
