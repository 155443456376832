import React from 'react';
import Link from 'gatsby-link';
import cover from './cover2.png';
import ipadBook from './cover3.png';
import japan from './japan.jpg';
import foreword from './foreword2.png';
import basics from './basics.png';
import infla from './infla.png';
import styles from './grip.module.scss';
import cx from 'classnames';

const GripCover = () => (
  <div className={styles.grip}>
    <div className={cx(styles.uvpbook)}>
      <h1> Attention Wanderer... </h1>
      <h2>
        Want to improve your grip on life? <br />
        You are in the right place.
      </h2>
      <a href="https://gum.co/grip33/labor-day?wanted=true" target="_blank" data-gumroad-single-product="true"><img className={styles.cover} src={cover} alt="Grip" width="600"/></a>
      <a href="https://gum.co/grip33/labor-day?wanted=true" target="_blank" data-gumroad-single-product="true">
        <button className={cx(styles.buy, styles.inverse)}>
          Buy Grip Now
        </button>
      </a>
    </div>
    <div className={styles.section}>
      <div className={styles.innerSection}>
        <div className={styles.secondaryuvp}>
          These 33 Principles transformed my life.
          Physically, emotionally and intellectually.
        </div>
        <p className={styles.paragraph}>A journey that has taken me to visit <span className={styles.bold}>50+ countries</span>.</p>
        <p className={styles.paragraph}>I <span className={styles.bold}>lived in four countries</span> with totally different cultures and philosophies including Spain, Ireland, and Japan.</p>
        <p className={styles.paragraph}>In these locations, I carefully observed how <span className={styles.bold}>the meaning of work</span> changes and how it influences everything else.</p>
        <p className={styles.paragraph}>I was an employee for a <span className={styles.bold}>video game franchise, a startup incubator</span> and a marketing company.</p>
        <p className={styles.paragraph}>I started my <span className={styles.bold}>own online startup</span> and consultancy.</p>
        <div className={styles.imageCenter}>
          <img className={styles.cover} src={japan} alt="Grip" width="800"/>
        </div>
        <p className={styles.paragraph}>From all these experiences, I learned that the <span className={styles.bold}>bullshit changes</span> from place to place but what matters stay constant.</p>
        <p className={styles.paragraph}>
          Now, I want to share with you everything I learned behind enemy lines.
        </p>
        <p className={styles.paragraph}>Grip condenses all my learnings into <span className={styles.bold}>33 universal principles</span></p>
      </div>
    </div>
    <div className={cx(styles.section)}>
      <div className={styles.innerSection}>
        <h2> How does it work? </h2>
        <p className={styles.paragraph}>
          The book is a <span className={styles.bold}>full-color PDF</span> with interactive links.
        </p>
        <p className={styles.paragraph}>
          Every principle is fully packed with information, peppered with footnotes (+200 links) but concise and clear.
        </p>
        <div className={styles.imageCenter}>
          <img className={styles.cover} src={basics} alt="Grip" width="800"/>
          <span className={styles.italic}> Basics from Principle #1: Nourish </span>
        </div>
        <p className={styles.paragraph}>
          No Filling. <b>No BS</b>. Just packed with actionable information.
        </p>
        <p className={styles.paragraph}>
          Every principle is explained through different sections that look like this.
        </p>
        <div className={styles.imageCenter}>
          <img className={styles.cover} src={infla} alt="Grip" width="800"/>
          <span className={cx(styles.italic, styles.ita2)}> Inflammation from Principle #1: Nourish </span>
        </div>
        <p className={styles.paragraph}>
          Then, you can expand your knowledge with <span className={styles.bold}>3 References to dig deeper.</span>
          <br />Podcasts, videos, books, and articles.
        </p>
      </div>
    </div>
    <div className={cx(styles.section, styles.colored)}>
      <div className={cx(styles.innerSection,styles.buySection)}>
        <div className={styles.leftBuy}>
          <h2> Get a Grip on Life! </h2>
          <h3> Just $1 per principle </h3>
          <p> Level-up your life  with Thirty Three time tested principles.</p>
          <a href="https://gum.co/grip33/labor-day?wanted=true" target="_blank" data-gumroad-single-product="true">
            <button className={styles.buy}>
              Get Grip
            </button>
          </a>
        </div>
        <div className={styles.rightBuy}>
          <img className={styles.ipadCover} src={ipadBook} alt="Grip" width="450"/>
        </div>
      </div>
    </div>
    <div className={cx(styles.section, styles)}>
      <div className={styles.innerSection}>
        <h2> Why? </h2>
        <p>
          There is no worthier goal to pursue than trying to be the best self you can be to then help as many other people as possible.
        </p>
        <blockquote>
          "If you want to help others, first set your house straight."
        </blockquote>
        <p>
          To be able to do that, you must first tune inwards to observe yourself, assess your current status, and consistently work towards improvement.
        </p>
        <p>
          The <span className={styles.bold}>first section</span> of the book focuses on your three inner centers:
        </p>
        <p>
          <span className={styles.bold}>Physical, Emotional and Intellectual</span>.
        </p>
        <p>
          Once you have gained awareness, you can start exploring your relationships with the external world and how they affect you.
        </p>
        <p>
          The <span className={styles.bold}>second section</span> covers the most important aspects of our lives like wealth, relationships, career, and leisure.
        </p>
      </div>
    </div>
    <div className={cx(styles.section)}>
      <div className={styles.innerSection}>
        <h2>Overwhelmed by the amount of information?</h2>
        <p>You are not alone.</p>
        <p>
          In this day and age, it is really difficult to separate signal from noise. I specifically designed this book to combat information fatigue by following these principles:
        </p>
        <ul>
          <li><b>Pareto Rule</b>: 80% of all the ‘juice’ comes from 20% of the 'oranges'. We are going to focus on these A-grade insights-rich oranges.</li>
          <li><b>Rule of Thirds</b>: The book is divided into three sections, each of them divided into three subsections, which… You get it.</li>
          <li><b>Lindy Effect</b>: Lindy Effect. Preference for tested methodologies and answers that have withstood the test of time. Bringing you the best mentors there are, dead or alive.</li>
        </ul>
      </div>
    </div>
    <div className={cx(styles.section, styles.colored)}>
      <div className={styles.innerSection}>
        <h2> 30-Day Money Back Guarantee </h2>
        <p>If Grip isn't helping you, just shoot me an email and I will completely refund your purchase. No questions asked. </p>

        <p>Hell, if you are in the Bay Area I will even buy you a coffee to learn more.</p>
        <p><b>If Grip doesn't help you, I don't want your money.</b></p>
      </div>
    </div>
  </div>
)

export default GripCover;
